<template>
	<div>
		<div
			@click="editableContent"
			class="infoTable"
			v-if="!column"
		>
			<div class="infoTable__header">
				<h2 class="infoTable__name">{{ title }}</h2>
				<a
					href="#"
					class="infoTable__edit"
					v-if="download && !changeMod"
					@click="changeMod = true"
				>Редактировать</a>
				<a
					href="#"
					class="infoTable__edit"
					v-if="download && changeMod"
					@click="saveResult"
				>Сохранить</a>
			</div>
			<div class="infoTable__body">
				<div
					class="infoTable__row"
					v-for="(data, index) in content"
					:key="data + index"
				>
					<div class="infoTable__col infoTable__col_header">{{ data.header }}</div>
					<input
						ref="editable"
						type="text"
						v-model="data.body"
						class="infoTable__col infoTable__col_editable"
						:class="{infoTable__col_change: changeMod}"
						:disabled="!changeMod"
					>
				</div>
			</div>
		</div>
		<div
			@click="editableContent"
			class="infoTable"
			v-else
		>
			<div class="infoTable__header">
				<h2 class="infoTable__name">{{ title }}</h2>
			</div>
			<div class="infoTable__body">
				<div class="infoTable__row">
					<div
						class="infoTable__col infoTable__col_header"
						v-for="item in headers"
						:class="item.size"
						:key="item.header"
					>{{ item.header }}
					</div>
				</div>
				<div
					class="infoTable__row"
					v-for="col in content"
				>
					<div
						class="infoTable__col"
						:class="cell.size"
						v-for="cell in col.body"
						:key="cell.id"
					>{{ cell.content }}
					</div>
					<a
						download
						href="#"
						class="infoTable__download"
						v-if="download"
					>Скачать</a>
				</div>
			</div>
		</div>
		<Paginator
			v-if="totalPages > 1"
			v-model="currentPage"
			:totalPages="totalPages"
			@changePage="changePage($event)"
		/>
	</div>
</template>

<script>
export default {
	components: {
		Paginator: () => import('@/components/block/Personal/Paginator.vue'),
	},
	props: ['textContent', 'title', 'column', 'headers', 'download', 'totalPages'],
	data()
	{
		return {
			actualContent: false,
			changeMod: false,
			currentPage: 1,
		}
	},
	computed:
		{
			content:
				{
					get: function ()
					{
						return this.actualContent ? this.actualContent : this.textContent;
					},
					set: function (newValue)
					{
						this.actualContent = newValue
					}
				},
		},
	updated()
	{
		this.changeMod && this.$refs.editable[0].focus();
	},
	methods: {
		changePage(val)
		{
			this.currentPage = val;
			this.$emit('updateList', {page: val, sort: 'date', desc: true});
		},
		saveResult()
		{
			this.changeMod = false;
			this.$emit('updateProperties', this.content)
		},
		editableContent(event)
		{
			event.preventDefault();

			if (event.target.tagName === 'A')
			{
				const inputs = event.currentTarget.querySelectorAll('input');

				inputs.forEach((item) =>
				{
					item.readOnly = false;
				});
			}
		}
	}
}
</script>

<style lang="scss">
$gray: #979797;
$black: #3D4248;
$lightgray: #F0F0F0;
$green: #40AE49;
$lightgreen: #F0F8F0;

.infoTable
{
	margin-bottom: 56px;
	font-family: Roboto, PT Sans, sans-serif;

	&__header
	{
		display: flex;
		justify-content: space-between;
	}

	&__download
	{
		display: flex;
		color: $green;
		font-size: 14px;
		font-weight: 700;
		text-decoration: underline;
		flex-grow: 1;
		justify-content: flex-end;

		@media (max-width: 1300px)
		{
			font-size: 0;
			text-decoration: none;
			position: absolute;
			right: 0;
		}

		&:before
		{
			content: '';
			background: url("~@/assets/img/download.svg");
			background-size: cover;
			height: 16px;
			top: 3px;
			width: 15px;
			display: inline-block;
			position: relative;
			margin-right: 8px;
		}
	}

	&__name
	{
		font-weight: 700;
		color: $black;
		font-size: 24px;
		margin-bottom: 10px;
	}

	&__edit
	{
		color: $green;
		font-size: 14px;
		font-weight: 700;
		text-decoration: underline;
		padding-bottom: 5px;

		&:before
		{
			content: '';
			display: inline-block;
			position: relative;
			height: 13px;
			width: 13px;
			background: url("~@/assets/img/pencil.svg");
			background-size: cover;
			margin-right: 8px;
		}
	}

	&__row
	{
		display: flex;
		justify-content: flex-start;
		border-bottom: 2px solid $lightgray;
		padding: 12px 0;
		position: relative;
		align-items: center;

		& input
		{
			flex-grow: 1;
			background-color: inherit;
			@media (max-width: 990px)
			{
				margin-right: 0;
				font-size: 15px;
			}
		}
	}

	&__col
	{
		font-size: 18px;
		color: $black;
		margin-right: 50px;
		border-left: 2px solid transparent;
		border-right: none;
		border-top: none;
		border-bottom: none;

		&_editable
		{
			padding: 5px;
		}

		&_change
		{
			border-color: $green;
			background: $lightgreen !important;
		}

		@media (max-width: 990px)
		{
			font-size: 14px;
			margin-right: 10px !important;
		}

		@media (max-width: 1370px)
		{
			margin-right: 20px;
		}

		&_header
		{
			color: $gray;
			font-size: 14px;
			flex-basis: 315px;
			max-width: 315px;

			@media (max-width: 990px)
			{
				flex-basis: 200px;
				max-width: 200px;
			}
		}

		&_small
		{
			flex-basis: 50px;
			max-width: 50px;

			@media (max-width: 990px)
			{
				flex-basis: 30px;
				max-width: 30px;
			}
		}

		&_middle
		{
			flex-basis: 159px;
			max-width: 159px;
			margin-right: 0;

			@media (max-width: 990px)
			{
				flex-basis: 110px;
				max-width: 110px;
			}
		}

		&_large
		{
			flex-basis: 190px;
			max-width: 190px;

			@media (max-width: 990px)
			{
				flex-basis: 90px;
				max-width: 90px;
			}
		}

		&_grow
		{
			flex: 1 1 0;
		}
	}
}
</style>
